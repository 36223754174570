export const endpoints = {
  login: "login",
  changepassword: "change-password",
  edituser: "edit-user",
  getuser: "get-user",
  adminuserlist: "admin-user-list",
  adminverifyuser: "admin-verify-user",
  // news crud..............
  addnews: "add-news",
  getnewslistadmin: "get-news-list-admin",
  getnewsditailsadmin: "get-news-ditails-admin",
  updatenewsbyadmin: "update-news-by-admin",
  // comman image upload.....................
  upload_image: "upload_image",
  delete_image: "delete-image",
  // cms.....................
  getcms: "get-cms",
  cms: "cms",
  // contact us..................
  getcontactusdata: "get-contact-us-data",
  getcontactusstatusupdate: "get-contact-us-status-update",
  sendmailtouser: "send-mail-to-user",
  // fashion...................
  addfashioncategory: "add-fashion-category",
  updatefashioncategory: "update-fashion-category",
  getfashioncategories: "get-fashion-categories",
  getfashioncategory: "get-fashion-category",
  deletefashioncategory: "delete-fashion-category",
  // brand style.....................
  addbrandstyle: "add-brand-style",
  updatebrandstyle: "update-brand-style",
  getbrandstyles: "get-brand-styles",
  updatebrandstyle_status: "update-brand-style_status",
  // accessories..................
  addAccessory: "add-Accessory",
  getAccessorys: "get-Accessorys",
  editAccessory: "edit-Accessory",
  updateAccessorystatus: "update-Accessory-status",
  // category.................
  addcategory: "add-category",
  getcategories: "get-categories",
  editcategory: "edit-category",
  updatecategorystatus: "update-category-status",
  // sub category...............
  addsubcategory: "add-subcategory",
  getsubcategories: "get-subcategories",
  editsubcategory: "edit-subcategory",
  updatesubcategorystatus: "update-subcategory-status",
  // types.........................
  addtype: "add-type",
  gettypes: "get-types",
  edittype: "edit-type",
  updatetypestatus: "update-type-status",
  // sub types....................
  addSubtype: "add-Subtype",
  getSubtypes: "get-Subtypes",
  editSubtype: "edit-Subtype",
  updateSubtypestatus: "update-Subtype-status",
  // getdata of category.................
  getdatacategories: "getdatacategories",
  getdatasubcategories: "getdatasubcategories",
  getdatatypes: "getdatatypes",
  getdatasubtypes: "getdatasubtypes",
  getdataspecificitems: "getdataspecific-items",
  // certificate................
  addcertificate: "add-certificate",
  getcertificate: "get-certificate",
  editcertificate: "edit-certificate",
  togglecertificate: "toggle-certificate",
  // brand value...........................
  addbrandvalue: "add-brand-value",
  getbrandvalues: "get-brand-values",
  editbrandvalue: "edit-brand-value",
  togglebrandvalue: "toggle-brand-value",
  // brand sub value.....................
  addsubbrandvalue: "add-sub-brand-value",
  getsubbrandvalues: "get-sub-brand-values",
  editsubbrandvalue: "edit-sub-brand-value",
  togglesubbrandvalue: "toggle-sub-brand-value",
  getbrandvaluebyadmin: "get-brand-value-by-admin",
  // dashboard......................
  dashboard: "dashboard",
  // delete................
  softdeletebytype: "soft-delete-by-type",
  getsuscriberditailsadmin: "get-suscriber-ditails-admin",
};

import useLoading from "components/Loader/useLoading";
import axios from "../../../../Schemas/Apiurl";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import { Headers } from "hooks/Fetchdata";
import Loader from "../../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { AddBrandvalueSchemas } from "Schemas";
import Fetchdata from "hooks/Fetchdata";

const AddBrandsubvalue = () => {
  const initialValues = {
    ENGLISH: "",
    GERMAN: "",
    FRENCH: "",
    ITALIAN: "",
    SPANISH: "",
    brand_values_id: "",
  };
  const navigate = useNavigate();
  const { loading, startLoading, stopLoading } = useLoading();
  const header = Headers();
  const { response } = Fetchdata(endpoints.getbrandvaluebyadmin, {}, "GET");

  const onSubmit = async () => {
    if (values.brand_values_id !== "") {
      startLoading();
      try {
        const res = await axios.post(
          endpoints.addsubbrandvalue,
          {
            ENGLISH: values.ENGLISH,
            GERMAN: values.GERMAN,
            FRENCH: values.FRENCH,
            ITALIAN: values.ITALIAN,
            SPANISH: values.SPANISH,
            brand_values_id: values.brand_values_id,
          },
          header
        );
        if (res.data.status === 1) {
          navigate("/brandsubvalue");
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        stopLoading();
        // console.log(res);
      } catch (error) {
        console.log(error);
        stopLoading();
      }
    } else {
      toast.error("Select Brand value");
    }
  };

  const { handleChange, handleSubmit, handleBlur, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
      validationSchema: AddBrandvalueSchemas,
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDBox mb={2}>
          <select
            name={"brand_values_id"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.brand_values_id}
            className="selecttag"
          >
            <option value="" selected disabled>
              Select Brand value
            </option>
            {response?.data?.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.English}
                </option>
              );
            })}
          </select>
          {/* {errors.category_id
            ? touched.category_id && <p className="errortag">{errors.category_id}</p>
            : null} */}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="ENGLISH"
            onBlur={handleBlur}
            label="English"
            fullWidth
          />
          {errors.ENGLISH ? touched.ENGLISH && <p className="errortag">{errors.ENGLISH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="GERMAN"
            onBlur={handleBlur}
            label="German"
            fullWidth
          />
          {errors.GERMAN ? touched.GERMAN && <p className="errortag">{errors.GERMAN}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="FRENCH"
            onBlur={handleBlur}
            label="French"
            fullWidth
          />
          {errors.FRENCH ? touched.FRENCH && <p className="errortag">{errors.FRENCH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="ITALIAN"
            onBlur={handleBlur}
            label="Italian"
            fullWidth
          />
          {errors.ITALIAN ? touched.ITALIAN && <p className="errortag">{errors.ITALIAN}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="SPANISH"
            onBlur={handleBlur}
            label="Spanish"
            fullWidth
          />
          {errors.SPANISH ? touched.SPANISH && <p className="errortag">{errors.SPANISH}</p> : null}
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" fullWidth>
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default AddBrandsubvalue;

/* eslint-disable react/prop-types */
import { Modal } from "react-bootstrap";
import "./Modal.css";

const Deletepopup = ({
  setShow,
  show,
  handleClose,
  imgg,
  name,
  handledelete,
  isimageshow = true,
  isbloack = false,
}) => {
  return (
    <>
      <Modal
        show={show.isOpen}
        onHide={handleClose}
        backdrop="static"
        size="md"
        className="z-index-add-media"
        centered
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        {/* <span className="span-delete-heres">View Product Detail</span> */}
        {/* <hr /> */}
        <Modal.Body>
          <div className="">
            <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
              {isimageshow && <img src={imgg} alt="" className="popupimage" />}
              <div className="name-post-span" style={{ marginTop: "0.5rem" }}>
                <span>{name}</span>
              </div>
              <span className="span-delete-sure-you text-center">
                Are you sure you want to{" "}
                {isbloack ? (show?.id?.isBlockedByAdmin ? "unblock" : "block") : "delete"} {name}?
                <br />
              </span>
              <div className="d-flex align-items-center gap-2">
                <button
                  // style={{ background: "#6a51a7", color: "#fff" }}
                  onClick={() => setShow(false)}
                  className="createbrandbtn"
                >
                  No
                </button>
                <button
                  style={{
                    // background: "#fff",
                    color: "#6a51a7",
                    border: "1px solid #6a51a7",
                  }}
                  className="createbrandbtn"
                  onClick={handledelete}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Deletepopup;

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "../../Schemas/Apiurl";
import Loader from "components/Loader/Loader";
import useLoading from "components/Loader/useLoading";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Fetchdata from "hooks/Fetchdata";
import { Headers } from "hooks/Fetchdata";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Termscondition = () => {
  const [content, setContent] = useState("");
  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [content4, setContent4] = useState("");
  const [content5, setContent5] = useState("");
  const { loading, startLoading, stopLoading } = useLoading();

  const header = Headers();

  const { response, isloading } = Fetchdata(
    endpoints.getcms,
    { cms_type: "Term", user_type: "FashonBrand" },
    "POST"
  );

  const Addterms = async (e) => {
    e.preventDefault();
    if (
      content !== "" ||
      content2 !== "" ||
      content3 !== "" ||
      content4 !== "" ||
      content5 !== ""
    ) {
      startLoading();
      try {
        const res = await axios.post(
          endpoints.cms,
          {
            id: response?.data?.id,
            cms_type: "Term",
            user_type: "FashonBrand",
            ENGLISH: content,
            GERMAN: content2,
            FRENCH: content3,
            ITALIAN: content4,
            SPANISH: content5,
          },
          header
        );
        if (res.status === 200) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        stopLoading();
        // console.log(res);
      } catch (error) {
        console.log(error);
        stopLoading();
      }
    } else {
      toast.error("please enter text");
    }
  };
  useEffect(() => {
    setContent(response?.data?.ENGLISH);
    setContent2(response?.data?.FRENCH);
    setContent3(response?.data?.GERMAN);
    setContent4(response?.data?.ITALIAN);
    setContent5(response?.data?.SPANISH);
  }, [response]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <form onSubmit={Addterms} className="d-flex flex-column gap-2">
        <span>English</span>
        <CKEditor
          editor={ClassicEditor}
          data={isloading ? "Loading..." : content ? content : ""}
          onChange={(e, editor) => setContent(editor.getData())}
        />
        <span>FRENCH</span>
        <CKEditor
          editor={ClassicEditor}
          data={isloading ? "Loading..." : content2 ? content2 : ""}
          onChange={(e, editor) => setContent2(editor.getData())}
        />
        <span>GERMAN</span>
        <CKEditor
          editor={ClassicEditor}
          data={isloading ? "Loading..." : content3 ? content3 : ""}
          onChange={(e, editor) => setContent3(editor.getData())}
        />
        <span>ITALIAN</span>
        <CKEditor
          editor={ClassicEditor}
          data={isloading ? "Loading..." : content4 ? content4 : ""}
          onChange={(e, editor) => setContent4(editor.getData())}
        />
        <span>SPANISH</span>
        <CKEditor
          editor={ClassicEditor}
          data={isloading ? "Loading..." : content5 ? content5 : ""}
          onChange={(e, editor) => setContent5(editor.getData())}
        />
        <button
          type="submit"
          className="createbrandbtn mt-2 mb-2"
          style={{ width: "100px", height: "45px" }}
        >
          Submit
        </button>{" "}
      </form>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default Termscondition;

import { Card } from "@mui/material";
import axios from "../../Schemas/Apiurl";
import Simpleloader from "components/Loader/Simpleloader";
import useLoading from "components/Loader/useLoading";
import MDBox from "components/MDBox";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Fetchdata from "hooks/Fetchdata";
import { Headers } from "hooks/Fetchdata";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import { useState } from "react";
import Replymodal from "components/Modals/Replymodal";

const ContactUs = () => {
  const { loading, startLoading, stopLoading } = useLoading();
  const [show, setShow] = useState({ id: null, isOpen: false });
  const [text, settext] = useState({ reply: "", subject: "" });
  const { response, isloading, getdata } = Fetchdata(
    endpoints.getcontactusdata,
    { page: "1", role_id: "2" },
    "POST"
  );
  const header = Headers();
  // console.log(response);

  const handlechange = async (item) => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.getcontactusstatusupdate,
        { id: item.contact_id },
        header
      );
      stopLoading();
      if (res.data.status === 1) {
        getdata();
        toast.success(res.data.message);
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  const handlereply = async (e) => {
    e.preventDefault();
    startLoading();
    try {
      const res = await axios.post(
        endpoints.sendmailtouser,
        {
          email: show.id.email,
          subject: text.subject,
          body: text.reply,
        },
        header
      );
      stopLoading();
      if (res.data.status === 1) {
        setShow({ isOpen: false });
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  // console.log(text);
  return (
    <>
      <Replymodal
        reply={show}
        handleClose={() => setShow({ isOpen: false })}
        handlereply={handlereply}
        setreply={setShow}
        settext={settext}
        text={text}
      />
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={4.5}>
          {isloading ? (
            <Simpleloader style={{ height: "300px" }} />
          ) : (
            <>
              {response?.pagination?.total_pages === 0 ? (
                <h1 className="text-center mt-3">{response?.message}</h1>
              ) : (
                <Card>
                  <Table responsive="xxl" className="vouchertable mt-3">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>User image</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Message</th>
                        <th>ContacUs image</th>
                        <th>Read</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {response?.data?.map((item, index) => {
                        return (
                          <tr className="" key={index}>
                            <td className="hover-color-change">{index + 1}</td>
                            <td className="hover-color-change">
                              <img
                                src={item.user_image}
                                alt=""
                                style={{ width: "30px", height: "30px" }}
                              />
                            </td>
                            <td className="hover-color-change">{item.name}</td>
                            <td className="hover-color-change">{item.email}</td>
                            <td className="hover-color-change">{item.message}</td>
                            <td className="hover-color-change">
                              <img
                                src={item.contacUs_image}
                                alt=""
                                style={{ width: "30px", height: "30px" }}
                              />
                            </td>
                            <td>
                              <input
                                className="checkboxx"
                                onChange={() => handlechange(item)}
                                type="checkbox"
                                checked={item.is_read === 1 ? true : false}
                              />
                            </td>
                            <td>
                              <button
                                onClick={() => setShow({ id: item, isOpen: true })}
                                style={{ width: "100px" }}
                                className="createbrandbtn"
                              >
                                Reply
                              </button>{" "}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card>
              )}
            </>
          )}
        </MDBox>
        {loading && <Loader />}
      </DashboardLayout>
    </>
  );
};

export default ContactUs;

import { useEffect, useState } from "react";
import apiurl from "../Schemas/Apiurl";
import { useDispatch, useSelector } from "react-redux";

const Fetchdata = (url, params, method) => {
  const token = useSelector((state) => state.auth.admin);

  const [isloading, setisloading] = useState(false);
  const [response, setresponse] = useState();
  const [error, seterror] = useState();
  // const router = useRouter();
  const dispatch = useDispatch();

  const getdata = async () => {
    setisloading(true);
    try {
      const requestOptions = {
        method,
        url,
        headers: {
          Authorization: `bearer ${token?.token}`,
          "Content-Type": "application/json",
        },
      };
      if (method.toLowerCase() === "get") {
        delete requestOptions.data;
      } else {
        requestOptions.data = params;
      }
      const res = await apiurl(requestOptions);
      setresponse(res.data);
      setisloading(false);
      // console.log(res, "res...........");
    } catch (error) {
      if (error?.response?.status === 401) {
        // dispatch(setsession(true));
      }
      seterror(error?.response?.data?.message);
      // console.log(error);
      setisloading(false);
    } finally {
      setisloading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (isMounted) {
        await getdata();
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  return { response, isloading, getdata, setisloading, error };
};

export default Fetchdata;

export const Contenttype = {
  row: "application/json",
  formdata: "multipart/form-data",
};

export const Headers = (Contenttype = "application/json") => {
  const token = useSelector((state) => state.auth.admin);

  return {
    headers: {
      "Content-Type": Contenttype,
      Authorization: `bearer ${token?.token}`,
    },
  };
};

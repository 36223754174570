// rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import authReducer from "./authSlice";
import addressReducer from "./addressSlice";
import notification from "./notificationSlice";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem(_key, _value) {
      return Promise.resolve();
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();

const persistConfig = {
  key: "root",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedAddressReducer = persistReducer(persistConfig, addressReducer);
const notificationReducer = persistReducer(persistConfig, notification);

const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  address: persistedAddressReducer,
  notification: notificationReducer,
});

export { rootReducer };

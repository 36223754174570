// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Skeleton } from "@mui/material";

function ComplexStatisticsCard({
  color,
  title,
  activecount,
  deactivecount,
  count,
  percentage,
  contactus = false,
  isLoading = false,
  icon,
}) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          {/* <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography> */}
          <MDTypography variant="h4">{title}</MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={2} px={2}>
        {/* <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={percentage.color}
          >
            {percentage.amount}
          </MDTypography>
          &nbsp;{percentage.label}
        </MDTypography> */}
        {contactus ? (
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="d-flex flex-column align-items-center w-100 gap-1">
              <span>Read</span>
              {isLoading ? (
                <Skeleton width={40} height={40} variant="text" />
              ) : (
                <MDTypography variant="button" fontWeight="light" color="text">
                  {activecount}
                </MDTypography>
              )}
            </div>
            <div className="d-flex flex-column align-items-center w-100 gap-1">
              <span>No Read</span>
              {isLoading ? (
                <Skeleton width={40} height={40} variant="text" />
              ) : (
                <MDTypography variant="button" fontWeight="light" color="text">
                  {deactivecount}
                </MDTypography>
              )}
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="d-flex flex-column align-items-center w-100 gap-1">
              <span>Total</span>
              {isLoading ? (
                <Skeleton width={40} height={40} variant="text" />
              ) : (
                <MDTypography variant="button" fontWeight="light" color="text">
                  {count}
                </MDTypography>
              )}
            </div>
            <div className="d-flex flex-column align-items-center w-100 gap-1">
              <span>Active</span>
              {isLoading ? (
                <Skeleton width={40} height={40} variant="text" />
              ) : (
                <MDTypography variant="button" fontWeight="light" color="text">
                  {activecount}
                </MDTypography>
              )}
            </div>
            <div className="d-flex flex-column align-items-center w-100 gap-1">
              <span>Deactivate</span>
              {isLoading ? (
                <Skeleton width={40} height={40} variant="text" />
              ) : (
                <MDTypography variant="button" fontWeight="light" color="text">
                  {deactivecount}
                </MDTypography>
              )}
            </div>
          </div>
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  activecount: PropTypes.string.isRequired,
  deactivecount: PropTypes.string.isRequired,
  contactus: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;

/* eslint-disable react/prop-types */
import "./Modal.css";
import { NavLink, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
// import { icons } from "../../assets/Icons";
import editicon from "../../assets/edit-button-svgrepo-com.svg";
import logouticon from "../../assets/logout.svg";
import { useDispatch } from "react-redux";
import password from "../../assets/password-account-security-reset-safety-svgrepo-com.svg";
import { logout } from "../../redux/authSlice";

const Modal = ({ setShow }) => {
  const dispatch = useDispatch();

  const logoutt = () => {
    setShow(false);
    toast.success("Logout successfully");
    localStorage.removeItem("USERTOKEN");
    dispatch(logout());
    navigate("/authentication/sign-in");
  };

  const navigate = useNavigate();
  return (
    <div className="Main-box-linked">
      <ul className="two-linkes-available" style={{ padding: "0" }}>
        <li>
          <NavLink
            to="/Profile"
            className="flex-outs-main-icons"
            style={{ backgroundColor: "#fff" }}
            // onClick={() => setShow(false)}
          >
            <div className="flex-outs-main-icons" style={{ backgroundColor: "#fff" }}>
              <img style={{ width: "20px" }} src={editicon} alt="" />
              <span style={{ fontSize: "17px", fontWeight: "500", color: "#000000" }}>Profile</span>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/changepassword"
            style={{ backgroundColor: "#fff" }}
            className="flex-outs-main-icons"
            onClick={() => setShow(false)}
          >
            <img src={password} style={{ width: "22px" }} alt="" />
            <span>Change Password</span>
          </NavLink>
        </li>
        <li onClick={() => logoutt()} style={{ backgroundColor: "#fff", cursor: "pointer" }}>
          <div className="flex-outs-main-icons" style={{ backgroundColor: "#fff" }}>
            {/* <span>{icons.logout}</span> */}
            <img style={{ width: "20px" }} src={logouticon} alt="" />
            <span style={{ fontSize: "17px", fontWeight: "500", color: "#000000" }}>Logout</span>
          </div>
        </li>

        {/* <hr className="m-0" /> */}
      </ul>
    </div>
  );
};

export default Modal;

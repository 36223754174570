import { Card } from "@mui/material";
import Loader from "components/Loader/Loader";
import Simpleloader from "components/Loader/Simpleloader";
import useLoading from "components/Loader/useLoading";
import MDBox from "components/MDBox";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Fetchdata from "hooks/Fetchdata";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import axios from "../../../Schemas/Apiurl";
import { Headers } from "hooks/Fetchdata";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import Deletepopup from "components/Modals/Deletepopup";
import { useState } from "react";

const SubTypes = () => {
  const [show, setShow] = useState({ id: null, isOpen: false });
  const { response, isloading, getdata } = Fetchdata(endpoints.getSubtypes, {}, "GET");
  const { loading, startLoading, stopLoading } = useLoading();

  const navigate = useNavigate();
  const header = Headers();

  const handlestatuschange = async (item) => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.updatesubcategorystatus,
        { id: item.id, action: item.active === "1" ? "0" : "1" },
        header
      );
      stopLoading();
      if (res.data.status === 1) {
        getdata();
        toast.success(res.data.message);
      } else {
        toast.success(res.data.message);
      }
      //   console.log(res);
    } catch (error) {
      stopLoading();
      console.log(error);
    }
  };

  const handledelete = async () => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.softdeletebytype,
        { id: show?.id?.id, type: "subtypes", value: 1 },
        header
      );
      //   console.log(res);
      if (res.data.status === 1) {
        setShow({ isOpen: false });
        getdata();
        toast.success(res.data.message);
      } else if (res.data.status === 0) {
        toast.error(res.data.message);
      }
      stopLoading();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      stopLoading();
    }
  };

  return (
    <DashboardLayout>
      <Deletepopup
        show={show}
        handleClose={() => setShow({ isOpen: false })}
        handledelete={handledelete}
        isimageshow={true}
        name={show?.id?.ENGLISH}
        imgg={show?.id?.image}
        setShow={setShow}
      />
      <DashboardNavbar />
      <MDBox mt={1}>
        <div className="d-flex align-items-center justify-content-end mb-3">
          <button
            onClick={() => navigate("/subtypes/AddSubtypes")}
            className="createbrandbtn"
            style={{ width: "150px", height: "45px" }}
          >
            Add Sub Types
          </button>
        </div>
        {isloading ? (
          <Simpleloader style={{ height: "300px" }} />
        ) : (
          <>
            {response?.pagination?.total_pages === 0 ? (
              <h1 className="text-center mt-3">{response?.message}</h1>
            ) : (
              <Card>
                <Table responsive="xxl" className="vouchertable mt-3">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Image</th>
                      <th>Sub type Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {response?.data?.map((item, index) => {
                      return (
                        <tr className="" key={index}>
                          <td className="hover-color-change">{index + 1}</td>
                          <td className="hover-color-change">
                            <img
                              src={item.image}
                              alt=""
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                          <td className="hover-color-change">{item.type}</td>
                          <td className="hover-color-change">{item.ENGLISH}</td>
                          <td>
                            <input
                              className="checkboxx"
                              onChange={() => handlestatuschange(item)}
                              type="checkbox"
                              checked={item.active === "1" ? true : false}
                            />
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                navigate("/subtypes/EditSubtypes", {
                                  state: { item },
                                })
                              }
                              className="createbrandbtn"
                            >
                              <EditIcon color="#ffffff" />
                            </button>{" "}
                            <button
                              onClick={() => setShow({ id: item, isOpen: true })}
                              className="createbrandbtn"
                            >
                              <Delete color="#ffffff" />
                            </button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            )}
          </>
        )}
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default SubTypes;

/* eslint-disable react/prop-types */
import { CircularProgress } from "@mui/material";
import React from "react";

const Simpleloader = ({ style }) => {
  return (
    <div className="d-flex align-items-center justify-content-center loaderColor" style={style}>
      <CircularProgress style={{ color: "#000000" }} />
    </div>
  );
};

export default Simpleloader;

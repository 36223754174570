"use client";
import { Backdrop, CircularProgress } from "@mui/material";
import React, { useState } from "react";

const Loader = () => {
  const [open, setopen] = useState(true);
  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress />
    </Backdrop>
  );
};

export default Loader;

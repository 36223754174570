import { createSlice } from "@reduxjs/toolkit";

const addressSlice = createSlice({
  name: "address",
  initialState: {
    data: null,
    id: null,
  },
  reducers: {
    address: (state, action) => {
      state.data = action.payload;
    },
    removeaddress: (state) => {
      state.data = null;
    },
    addid: (state, action) => {
      state.id = action.payload;
    },
    removeid: (state) => {
      state.id = null;
    },
  },
});

export const { address, removeaddress, addid, removeid } = addressSlice.actions;

export default addressSlice.reducer;

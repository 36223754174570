import useLoading from "components/Loader/useLoading";
import axios from "../../../Schemas/Apiurl";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import { Contenttype } from "hooks/Fetchdata";
import { Headers } from "hooks/Fetchdata";
import Loader from "../../../components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
// import { AddnewsSchemas } from "Schemas";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { AddCategorySchemas } from "Schemas";
import Fetchdata from "hooks/Fetchdata";
// import Select from "react-select";

const EditSubtypes = () => {
  const initialValues = {
    ENGLISH: "",
    GERMAN: "",
    FRENCH: "",
    ITALIAN: "",
    SPANISH: "",
    title_image: "",
    id: "",
    category_ids: [],
  };
  const route = useLocation();
  const maindata = route.state.item;
  //   console.log(maindata);
  const { response, isloading, getdata } = Fetchdata(endpoints.gettypes, {}, "GET");

  //   console.log(response);
  const navigate = useNavigate();
  const { loading, startLoading, stopLoading } = useLoading();
  const header = Headers();
  const header2 = Headers(Contenttype.formdata);
  const [image, setimage] = useState();

  const onSubmit = async () => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.editsubcategory,
        {
          ENGLISH: values.ENGLISH,
          GERMAN: values.GERMAN,
          FRENCH: values.FRENCH,
          ITALIAN: values.ITALIAN,
          SPANISH: values.SPANISH,
          image: values.title_image,
          id: values.id,
          category_ids: values?.category_ids?.map((item) => item.value)?.join(","),
        },
        header
      );
      if (res.data.status === 1) {
        navigate("/subtypes");
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      stopLoading();
      // console.log(res);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      stopLoading();
    }
  };

  const { handleChange, handleSubmit, handleBlur, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
      validationSchema: AddCategorySchemas,
    });

  const handleimage = async (e, name) => {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("image", file);
    startLoading();
    try {
      const res = await axios.post(endpoints.upload_image, formdata, header2);
      if (res.data.status === 1) {
        setFieldValue(name, res.data.data);
      }
      //   console.log(res);
      stopLoading();
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  const handledelete = async () => {
    startLoading();
    try {
      const res = await axios.post(endpoints.delete_image, { img: values.title_image }, header);
      //   console.log(res);
      if (res.data.status === 1) {
        setFieldValue("title_image", "");
        toast.success(res.data.message);
      } else if (res.data.status === 0) {
        toast.error(res.data.message);
      }
      stopLoading();
    } catch (error) {
      console.log(error);
      stopLoading();
      toast.error(error?.response?.data?.message);
      if (error?.response?.data?.message === "Image not found") {
        setFieldValue("title_image", "");
        setimage("");
      }
    }
  };

  //   const options = response?.data?.map((item) => ({
  //     value: item.id,
  //     label: item.ENGLISH,
  //   }));

  useEffect(() => {
    for (const key of Object.keys(initialValues)) {
      if (key !== "title_image" || key !== "category_ids") {
        setFieldValue(key, maindata[key]);
      }
    }
    setFieldValue("title_image", maindata?.image);
    setFieldValue("category_ids", [{ value: maindata?.type_id }]);
  }, [maindata]);

  const handlecountryChange = (e) => {
    setFieldValue("category_ids", [{ value: e.target.value }]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDBox mb={2}>
          <select
            name={"category_id"}
            onChange={handlecountryChange}
            onBlur={handleBlur}
            value={values?.category_ids?.at(-1)?.value}
            className="selecttag"
          >
            <option value="" selected disabled>
              Select
            </option>
            {response?.data?.map((item) => {
              return (
                <option value={item.id} key={item.CountryId}>
                  {item.ENGLISH}
                </option>
              );
            })}
          </select>
          {errors.category_ids
            ? touched.category_ids && <p className="errortag">{errors.category_ids}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="ENGLISH"
            onBlur={handleBlur}
            value={values.ENGLISH}
            label="English"
            fullWidth
          />
          {errors.ENGLISH ? touched.ENGLISH && <p className="errortag">{errors.ENGLISH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="GERMAN"
            onBlur={handleBlur}
            value={values.GERMAN}
            label="German"
            fullWidth
          />
          {errors.GERMAN ? touched.GERMAN && <p className="errortag">{errors.GERMAN}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="FRENCH"
            value={values.FRENCH}
            onBlur={handleBlur}
            label="French"
            fullWidth
          />
          {errors.FRENCH ? touched.FRENCH && <p className="errortag">{errors.FRENCH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="ITALIAN"
            value={values.ITALIAN}
            onBlur={handleBlur}
            label="Italian"
            fullWidth
          />
          {errors.ITALIAN ? touched.ITALIAN && <p className="errortag">{errors.ITALIAN}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="SPANISH"
            value={values.SPANISH}
            onBlur={handleBlur}
            label="Spanish"
            fullWidth
          />
          {errors.SPANISH ? touched.SPANISH && <p className="errortag">{errors.SPANISH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <span>Title image</span>
          <MDInput
            type="file"
            onChange={(e) => handleimage(e, "title_image")}
            name="title_image"
            onBlur={handleBlur}
            disabled={values.title_image !== "" ? true : false}
            // label="Title spanish"
            fullWidth
          />
          <i style={{ fontSize: "14px" }}>If you want to edit image please delete old image</i>
          <div className="d-flex gap-1 mt-2">
            {values.title_image !== "" && (
              <>
                <img src={values.title_image} alt="" style={{ width: "200px" }} />
                <CloseIcon onClick={() => handledelete()} />
              </>
            )}{" "}
          </div>
          {errors.title_image
            ? touched.title_image && <p className="errortag">{errors.title_image}</p>
            : null}
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" fullWidth>
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default EditSubtypes;

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import { useSelector } from "react-redux";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import useLoading from "components/Loader/useLoading";
import { useNavigate } from "react-router-dom";
import { Headers } from "hooks/Fetchdata";
import Loader from "components/Loader/Loader";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { toast } from "react-toastify";
import axios from "../../../../Schemas/Apiurl";
import { Contenttype } from "hooks/Fetchdata";
import { endpoints } from "endpoints";
import Fetchdata from "hooks/Fetchdata";

function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  // const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const userdata = useSelector((state) => state.auth.admin);
  const [selectedFiled, setSelectedFiled] = useState({ file: null, url: null });
  const { response, getdata, isloading } = Fetchdata(
    endpoints.getuser,
    { user_type: userdata?.user_type },
    "POST"
  );
  // console.log(userdata);
  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    image: "",
  };

  const { loading, startLoading, stopLoading } = useLoading();
  // const navigate = useNavigate();
  const header = Headers();
  const header2 = Headers(Contenttype.formdata);
  const onSubmit = async () => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.edituser,
        {
          fname: values.fname,
          lname: values.lname,
          email: values.email,
          image: selectedFiled.url,
        },
        header
      );
      if (res.data.status === 1) {
        toast.success(res.data.message);
        // navigate("/dashboard");
        getdata();
      } else {
        toast.error(res.data.message);
      }
      // console.log(res);
      stopLoading();
    } catch (error) {
      // console.log(error);
      toast.error(error?.response?.data?.message);
      stopLoading();
    }
  };

  const { handleChange, handleSubmit, handleBlur, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
      // validationSchema: ChangepassSchemas,
    });

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setSelectedFiled({ file: file, url: URL.createObjectURL(file) });
  //   setFieldValue("image", URL.createObjectURL(file));
  // };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("image", file);
    startLoading();
    try {
      const res = await axios.post(endpoints.upload_image, formdata, header2);
      if (res.data.status === 1) {
        setFieldValue("image", res.data.data);
        setSelectedFiled({ url: res.data.data });
      }
      // console.log(res);
      stopLoading();
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  useEffect(() => {
    setFieldValue("fname", response?.data?.fname);
    setFieldValue("lname", response?.data?.lname);
    setFieldValue("email", response?.data?.email);
    setFieldValue("image", response?.data?.image);
    setSelectedFiled({ url: response?.data.image });
  }, [response]);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        {/* <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={userdata?.image} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {userdata?.fname}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="App"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      home
                    </Icon>
                  }
                />
                <Tab
                  label="Message"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      email
                    </Icon>
                  }
                />
                <Tab
                  label="Settings"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid> */}
        <MDBox pt={1} pb={3} px={3}>
          <MDBox component="form" onSubmit={handleSubmit} role="form">
            <div className="d-flex flex-column align-items-center justify-content-center gap-3">
              <div>
                <div className="img-first-user">
                  <label htmlFor="mal">
                    {selectedFiled.url && (
                      <img
                        src={selectedFiled ? selectedFiled.url : Profile}
                        alt="Profile"
                        className="user-camara-size"
                      />
                    )}
                    <div className="Camara-size">
                      <CameraAltIcon fontSize="large" />
                    </div>
                    {/* <img src={Camara} alt="Camera" className="Camara-size" /> */}
                  </label>
                  <input
                    type="file"
                    id="mal"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  {selectedFiled && <p> </p>}
                </div>
                <input type="file" id="mal" style={{ display: "none" }} />
              </div>
              <div className="d-flex w-100 align-items-center justify-content-center gap-3">
                <MDBox mb={1}>
                  <MDInput
                    type="text"
                    onChange={handleChange}
                    name="fname"
                    onBlur={handleBlur}
                    value={values.fname}
                    label="First Name"
                    fullWidth
                  />
                  {/* {errors.oldpassword
                ? touched.oldpassword && <p className="errortag">{errors.oldpassword}</p>
                : null} */}
                </MDBox>
                <MDBox mb={1}>
                  <MDInput
                    type="text"
                    onChange={handleChange}
                    name="lname"
                    value={values.lname}
                    onBlur={handleBlur}
                    label="Last Name"
                    fullWidth
                  />
                  {/* {errors.password
                ? touched.password && <p className="errortag">{errors.password}</p>
                : null} */}
                </MDBox>
              </div>
              <MDBox mb={1}>
                <MDInput
                  type="email"
                  onChange={handleChange}
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  label="Email"
                  fullWidth
                />
                {/* {errors.password
                ? touched.password && <p className="errortag">{errors.password}</p>
                : null} */}
              </MDBox>
              <MDBox mt={1} mb={1}>
                <MDButton
                  disabled={isloading ? true : false}
                  type="submit"
                  variant="gradient"
                  color="info"
                >
                  Save
                </MDButton>
              </MDBox>
            </div>
          </MDBox>
        </MDBox>
        {children}
      </Card>
      {loading && <Loader />}
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;

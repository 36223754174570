import useLoading from "components/Loader/useLoading";
import axios from "../../Schemas/Apiurl";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import { Contenttype } from "hooks/Fetchdata";
import { Headers } from "hooks/Fetchdata";
import Loader from "../../components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
// import { AddnewsSchemas } from "Schemas";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { AddCategorySchemas } from "Schemas";
import Fetchdata from "hooks/Fetchdata";

const EditCategory = () => {
  const initialValues = {
    ENGLISH: "",
    GERMAN: "",
    FRENCH: "",
    ITALIAN: "",
    SPANISH: "",
    title_image: "",
    id: "",
  };
  const route = useLocation();
  const maindata = route.state.item;

  //   const { response } = Fetchdata(endpoints.getfashioncategory, { id: maindata?.id }, "POST");
  const [image, setimage] = useState();
  const navigate = useNavigate();
  const { loading, startLoading, stopLoading } = useLoading();
  const header = Headers();
  const header2 = Headers(Contenttype.formdata);

  const onSubmit = async () => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.editcategory,
        {
          ENGLISH: values.ENGLISH,
          GERMAN: values.GERMAN,
          FRENCH: values.FRENCH,
          ITALIAN: values.ITALIAN,
          SPANISH: values.SPANISH,
          image: values.title_image,
          id: values.id,
        },
        header
      );
      if (res.data.status === 1) {
        navigate("/category");
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      stopLoading();
      //   console.log(res);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      stopLoading();
    }
  };

  const { handleChange, handleSubmit, handleBlur, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
      validationSchema: AddCategorySchemas,
    });

  const handleimage = async (e, name) => {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("image", file);
    startLoading();
    try {
      const res = await axios.post(endpoints.upload_image, formdata, header2);
      if (res.data.status === 1) {
        setFieldValue(name, res.data.data);
        setimage(res.data.data);
      }
      // console.log(res);
      stopLoading();
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  const handledelete = async () => {
    startLoading();
    try {
      const res = await axios.post(endpoints.delete_image, { img: values.title_image }, header);
      //   console.log(res);
      if (res.data.status === 1) {
        setFieldValue("title_image", "");
        setimage("");
        toast.success(res.data.message);
      } else if (res.data.status === 0) {
        toast.error(res.data.message);
      }
      stopLoading();
    } catch (error) {
      console.log(error);
      stopLoading();
      toast.error(error?.response?.data?.message);
      if (error?.response?.data?.message === "Image not found") {
        setFieldValue("title_image", "");
        setimage("");
      }
    }
  };

  useEffect(() => {
    for (const key of Object.keys(initialValues)) {
      if (key !== "title_image") {
        setFieldValue(key, maindata[key]);
      }
    }
    setFieldValue("title_image", maindata?.image);
    setimage(maindata?.image);
  }, [maindata]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="ENGLISH"
            onBlur={handleBlur}
            value={values.ENGLISH}
            label="English"
            fullWidth
          />
          {errors.ENGLISH ? touched.ENGLISH && <p className="errortag">{errors.ENGLISH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="GERMAN"
            onBlur={handleBlur}
            value={values.GERMAN}
            label="German"
            fullWidth
          />
          {errors.GERMAN ? touched.GERMAN && <p className="errortag">{errors.GERMAN}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="FRENCH"
            value={values.FRENCH}
            onBlur={handleBlur}
            label="French"
            fullWidth
          />
          {errors.FRENCH ? touched.FRENCH && <p className="errortag">{errors.FRENCH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="ITALIAN"
            value={values.ITALIAN}
            onBlur={handleBlur}
            label="Italian"
            fullWidth
          />
          {errors.ITALIAN ? touched.ITALIAN && <p className="errortag">{errors.ITALIAN}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="SPANISH"
            value={values.SPANISH}
            onBlur={handleBlur}
            label="Spanish"
            fullWidth
          />
          {errors.SPANISH ? touched.SPANISH && <p className="errortag">{errors.SPANISH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <span>Title image</span>
          <MDInput
            type="file"
            onChange={(e) => handleimage(e, "title_image")}
            name="title_image"
            onBlur={handleBlur}
            disabled={values.title_image !== "" ? true : false}
            // label="Title spanish"
            fullWidth
          />
          <i style={{ fontSize: "14px" }}>If you want to edit image please delete old image</i>
          <div className="d-flex gap-1 mt-2">
            {values.title_image !== "" && (
              <>
                <img src={image} alt="" style={{ width: "200px" }} />
                <CloseIcon onClick={() => handledelete()} />
              </>
            )}{" "}
          </div>
          {errors.title_image
            ? touched.title_image && <p className="errortag">{errors.title_image}</p>
            : null}
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" fullWidth>
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default EditCategory;

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import "./News.css";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Fetchdata, { Headers } from "../../hooks/Fetchdata";
import { endpoints } from "endpoints";
import Simpleloader from "components/Loader/Simpleloader";
import { Table } from "react-bootstrap";
import { Delete, RemoveRedEyeTwoTone } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import Deletepopup from "components/Modals/Deletepopup";
import Loader from "components/Loader/Loader";
import { useState } from "react";
import useLoading from "components/Loader/useLoading";
import axios from "../../Schemas/Apiurl";
import { toast } from "react-toastify";

const News = () => {
  const { response, isloading, getdata } = Fetchdata(endpoints.getnewslistadmin, {}, "GET");
  const navigate = useNavigate();
  const [show, setShow] = useState({ id: null, isOpen: false });
  const { loading, startLoading, stopLoading } = useLoading();
  const header = Headers();

  const handledelete = async () => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.softdeletebytype,
        { id: show?.id?.id, type: "news", value: 1 },
        header
      );
      //   console.log(res);
      if (res.data.status === 1) {
        setShow({ isOpen: false });
        getdata();
        toast.success(res.data.message);
      } else if (res.data.status === 0) {
        toast.error(res.data.message);
      }
      stopLoading();
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  return (
    <DashboardLayout>
      <Deletepopup
        show={show}
        handleClose={() => setShow({ isOpen: false })}
        handledelete={handledelete}
        isimageshow={true}
        name={show?.id?.ENGLISH}
        imgg={show?.id?.title_image}
        setShow={setShow}
      />
      <DashboardNavbar />
      <MDBox mt={1}>
        <div className="d-flex align-items-center justify-content-end mb-3">
          <button
            onClick={() => navigate("/news/addnews")}
            className="createbrandbtn"
            style={{ width: "120px", height: "45px" }}
          >
            Add News
          </button>
        </div>
        {isloading ? (
          <Simpleloader style={{ height: "300px" }} />
        ) : (
          <>
            {response?.pagination?.total_pages === 0 ? (
              <h1 className="text-center mt-3">{response?.message}</h1>
            ) : (
              <Card>
                <Table responsive="xxl" className="vouchertable mt-3">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Title Image</th>
                      <th>Title</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {response?.data?.map((item, index) => {
                      return (
                        <tr className="" key={index}>
                          <td className="hover-color-change">{index + 1}</td>
                          <td className="hover-color-change">
                            <img
                              src={item.title_image}
                              alt=""
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                          <td className="hover-color-change">{item.TITLE_ENGLISH}</td>
                          <td>
                            {/* <button
                              onClick={() =>
                                navigate("/companion/companionDetail", {
                                  state: { item },
                                })
                              }
                              className="createbrandbtn"
                            >
                              <RemoveRedEyeTwoTone color="#ffffff" />
                            </button>{" "} */}
                            <button
                              onClick={() =>
                                navigate("/news/editnews", {
                                  state: { item },
                                })
                              }
                              className="createbrandbtn"
                            >
                              <EditIcon color="#ffffff" />
                            </button>{" "}
                            <button
                              className="createbrandbtn"
                              onClick={() => setShow({ id: item, isOpen: true })}
                            >
                              <Delete color="#ffffff" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            )}
          </>
        )}
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default News;

import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    admin: null,
    otptoken: null,
    pagenumber: null,
    registerdata: null,
  },
  reducers: {
    login: (state, action) => {
      state.admin = action.payload;
    },
    register: (state, action) => {
      state.registerdata = { ...state.registerdata, ...action.payload };
    },
    removeregister: (state) => {
      state.registerdata = null;
    },
    logout: (state) => {
      state.admin = null;
    },
    otptoken: (state, action) => {
      state.otptoken = action.payload;
    },
    removetoken: (state) => {
      state.otptoken = null;
    },
    savepagenumber: (state, action) => {
      state.pagenumber = action.payload;
    },
    removepagenumber: (state) => {
      state.pagenumber = null;
    },
  },
});
export const {
  login,
  logout,
  otptoken,
  removetoken,
  savepagenumber,
  removepagenumber,
  register,
  removeregister,
} = authSlice.actions;

export default authSlice.reducer;

import useLoading from "components/Loader/useLoading";
import axios from "../../Schemas/Apiurl";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import { Contenttype } from "hooks/Fetchdata";
import { Headers } from "hooks/Fetchdata";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { AddnewsSchemas } from "Schemas";

const Addnews = () => {
  const initialValues = {
    TITLE_ENGLISH: "",
    TITLE_GERMAN: "",
    TITLE_FRENCH: "",
    TITLE_ITALIAN: "",
    TITLE_SPANISH: "",
    DESCRIPTION_ENGLISH: "",
    DESCRIPTION_GERMAN: "",
    DESCRIPTION_FRENCH: "",
    DESCRIPTION_ITALIAN: "",
    DESCRIPTION_SPANISH: "",
    title_image: "",
    images: "",
  };

  const navigate = useNavigate();
  const { loading, startLoading, stopLoading } = useLoading();
  const header = Headers();
  const header2 = Headers(Contenttype.formdata);
  const onSubmit = async () => {
    startLoading();
    try {
      const res = await axios.post(endpoints.addnews, values, header);
      if (res.data.status === 1) {
        navigate("/news");
      }
      stopLoading();
      // console.log(res);
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  const { handleChange, handleSubmit, handleBlur, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
      validationSchema: AddnewsSchemas,
    });

  const handleimage = async (e, name) => {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("image", file);
    startLoading();
    try {
      const res = await axios.post(endpoints.upload_image, formdata, header2);
      if (res.data.status === 1) {
        setFieldValue(name, res.data.data);
      }
      // console.log(res);
      stopLoading();
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  console.log(values.title_image);
  console.log(values.images);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="TITLE_ENGLISH"
            onBlur={handleBlur}
            label="Title english"
            fullWidth
          />
          {errors.TITLE_ENGLISH
            ? touched.TITLE_ENGLISH && <p className="errortag">{errors.TITLE_ENGLISH}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="TITLE_GERMAN"
            onBlur={handleBlur}
            label="Title german"
            fullWidth
          />
          {errors.TITLE_GERMAN
            ? touched.TITLE_GERMAN && <p className="errortag">{errors.TITLE_GERMAN}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="TITLE_FRENCH"
            onBlur={handleBlur}
            label="Title french"
            fullWidth
          />
          {errors.TITLE_FRENCH
            ? touched.TITLE_FRENCH && <p className="errortag">{errors.TITLE_FRENCH}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="TITLE_ITALIAN"
            onBlur={handleBlur}
            label="Title italian"
            fullWidth
          />
          {errors.TITLE_ITALIAN
            ? touched.TITLE_ITALIAN && <p className="errortag">{errors.TITLE_ITALIAN}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="TITLE_SPANISH"
            onBlur={handleBlur}
            label="Title spanish"
            fullWidth
          />
          {errors.TITLE_SPANISH
            ? touched.TITLE_SPANISH && <p className="errortag">{errors.TITLE_SPANISH}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <textarea
            className="textareaaa"
            onChange={handleChange}
            name="DESCRIPTION_ENGLISH"
            onBlur={handleBlur}
            placeholder="DESCRIPTION ENGLISH"
          />
          {errors.DESCRIPTION_ENGLISH
            ? touched.DESCRIPTION_ENGLISH && (
                <p className="errortag">{errors.DESCRIPTION_ENGLISH}</p>
              )
            : null}
        </MDBox>
        <MDBox mb={2}>
          <textarea
            className="textareaaa"
            onChange={handleChange}
            name="DESCRIPTION_GERMAN"
            onBlur={handleBlur}
            placeholder="DESCRIPTION GERMAN"
          />
          {errors.DESCRIPTION_GERMAN
            ? touched.DESCRIPTION_GERMAN && <p className="errortag">{errors.DESCRIPTION_GERMAN}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <textarea
            className="textareaaa"
            onChange={handleChange}
            name="DESCRIPTION_FRENCH"
            onBlur={handleBlur}
            placeholder="DESCRIPTION FRENCH"
          />
          {errors.DESCRIPTION_FRENCH
            ? touched.DESCRIPTION_FRENCH && <p className="errortag">{errors.DESCRIPTION_FRENCH}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <textarea
            className="textareaaa"
            onChange={handleChange}
            name="DESCRIPTION_ITALIAN"
            onBlur={handleBlur}
            placeholder="DESCRIPTION ITALIAN"
          />
          {errors.DESCRIPTION_ITALIAN
            ? touched.DESCRIPTION_ITALIAN && (
                <p className="errortag">{errors.DESCRIPTION_ITALIAN}</p>
              )
            : null}
        </MDBox>
        <MDBox mb={2}>
          <textarea
            className="textareaaa"
            onChange={handleChange}
            name="DESCRIPTION_SPANISH"
            onBlur={handleBlur}
            placeholder="DESCRIPTION SPANISH"
          />
          {errors.DESCRIPTION_SPANISH
            ? touched.DESCRIPTION_SPANISH && (
                <p className="errortag">{errors.DESCRIPTION_SPANISH}</p>
              )
            : null}
        </MDBox>
        <MDBox mb={2}>
          <span>Title image</span>
          <MDInput
            type="file"
            onChange={(e) => handleimage(e, "title_image")}
            name="title_image"
            onBlur={handleBlur}
            // label="Title spanish"
            fullWidth
          />
          {errors.title_image
            ? touched.title_image && <p className="errortag">{errors.title_image}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <span>Images</span>
          <MDInput
            type="file"
            onChange={(e) => handleimage(e, "images")}
            name="images"
            onBlur={handleBlur}
            // label="Title spanish"
            fullWidth
          />
          {errors.email ? touched.email && <p className="errortag">{errors.email}</p> : null}
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" fullWidth>
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default Addnews;

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/landingpageimage.png";
import { useFormik } from "formik";
import { LoginSchemas } from "Schemas";
import axios from "../../../Schemas/Apiurl";
import { endpoints } from "endpoints";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/authSlice";
import useLoading from "components/Loader/useLoading";
import Loader from "components/Loader/Loader";
import { toast } from "react-toastify";

function Basic() {
  // const [rememberMe, setRememberMe] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };

  const { loading, startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    startLoading();
    try {
      const res = await axios.post(endpoints.login, {
        email: values.email,
        password: values.password,
      });
      if (res.data.status === 1) {
        dispatch(login(res.data.data));
        navigate("/dashboard");
      }
      // console.log(res);
      stopLoading();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      stopLoading();
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, errors, touched } = useFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: LoginSchemas,
  });

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            FBF
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" onSubmit={handleSubmit} role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                onChange={handleChange}
                name="email"
                onBlur={handleBlur}
                label="Email"
                fullWidth
              />
              {errors.email ? touched.email && <p className="errortag">{errors.email}</p> : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                onChange={handleChange}
                name="password"
                onBlur={handleBlur}
                label="Password"
                fullWidth
              />
              {errors.password
                ? touched.password && <p className="errortag">{errors.password}</p>
                : null}
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {loading && <Loader />}
    </BasicLayout>
  );
}

export default Basic;
